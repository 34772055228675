import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import config from '../../data/SiteConfig'
import { PostListing, SEO, HTML, Pagination } from '../components'
import { Container } from '@caldera-digital/theme'
import { FancyTitle } from '../components/FancyTitle/FancyTitle'
import { graphql } from 'gatsby'

class Listing extends React.Component {
  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges
    const { currentPageNum, pageCount } = this.props.pageContext
    const {
      data: {
        pagesYaml: { cover, intro },
      },
    } = this.props

    return (
      <Layout heroImage={cover}>
        <Helmet title={config.siteTitle} />
        <SEO />
        <Container>
          <HTML html={intro} />
          <FancyTitle>explore below</FancyTitle>
          <PostListing postEdges={postEdges} />

          <Pagination currentPageNum={currentPageNum} pageCount={pageCount} />

          {/* <FancyTitle>latest on Instagram</FancyTitle> */}
        </Container>
      </Layout>
    )
  }
}

export default Listing

/* eslint no-undef: "off" */
export const homeQuery = graphql`
  query HomeQuery($skip: Int!, $limit: Int!) {
    pagesYaml(id: { eq: "home" }) {
      intro
      id
      cover
    }
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      filter: { fileAbsolutePath: { glob: "!**/authors/*" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date(formatString: "MM/DD/YYYY")
            category
            author {
              frontmatter {
                name
              }
            }
          }
        }
      }
    }
  }
`
